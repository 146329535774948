
import CloudActivationErrorViaSuccessMail from "@/components/landingPages/cloudActivation/CloudActivationErrorViaSuccessMail.vue";
import CloudTrialActivationFlowViaSuccessMail from "@/components/landingPages/cloudActivation/CloudTrialActivationFlowViaSuccessMail.vue";
import {
  CloudActivationStatus,
  ServerActivationResponse,
  isRestClientError,
  storeErrorsMapping,
} from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";
import { registrationsService } from "@/services/registrations";

declare var window: any;

@Component({
  name: "CloudTrialActivationViaSuccessMail",
  components: {
    CloudActivationErrorViaSuccessMail,
    CloudTrialActivationFlowViaSuccessMail,
  },
})
export default class CloudTrialActivation extends Vue {
  hasInternalError = false;
  intervalCheckStatus!: any;
  currentStatus: CloudActivationStatus = "IN_PROGRESS";
  isDelayed: boolean = false;
  isReady: boolean = false;
  platformRegistrationLink: string = "";
  internalElapsedTime = 0;
  delayBetweenCheck = 7;
  activationResponse!: ServerActivationResponse;

  flowDataProps = {
    token: "",
    isReady: this.isReady,
    isDelayed: this.isDelayed,
    serverName: "",
    instanceUrl: "",
    cloudProvider: "",
    regionName: "",
    ssoType: "",
    platformRegistrationLink: "",
  };

  created() {
    this.activateServer();
  }

  mounted() {
    this.initStatusCheck();
    setTimeout(() => {
      this.sendToMarketo();
    }, 5000);
  }

  async activateServer() {
    try {
      const activationCode = this.$route.query.ac as string;
      const response = await this.$jfRegistrations.trialEnterpriseActivation(activationCode);
      this.setFlowDataProps(response);
      await this.checkStatus();
    } catch (e) {
      const response = e.httpBody;
      if (isRestClientError(e)) {
        const result = e.httpBody && e.httpBody.result;
        if (
          result &&
          Object.values(storeErrorsMapping.cloudReactivation).includes(result) &&
          result !== storeErrorsMapping.cloudReactivation.activationCodeAlreadyUsed
        ) {
          this.$jfNotification.error({ text: e.httpBody?.message });
        }
      }
      this.setFlowDataProps(response);
      await this.checkStatus();
    }
  }

  setFlowDataProps(response: ServerActivationResponse) {
    this.flowDataProps = {
      token: response.token,
      isReady: this.isReady,
      isDelayed: this.isDelayed,
      serverName: response.serverName,
      instanceUrl: response.instanceUrl,
      cloudProvider: response.cloudProvider,
      regionName: response.regionName,
      ssoType: response.ssoType,
      platformRegistrationLink: this.platformRegistrationLink,
    };
  }

  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("fxCol");
    }
    return classes;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  async sendToMarketo() {
    const marketoType = "evaluateCloudEnterpriseActivate";
    const comments = "Ent Trial Server Activation";
    try {
      await registrationsService.enterpriseTrialMarketo({
        args: {
          email: "",
          type: marketoType,
          cookie: this.getGoogleClientId(),
          serverName: this.flowDataProps.serverName,
          skipServerOwnerVerification: true,
        },
        data: { comments },
      });
    } catch (e) {
      this.$log.error(`error on calling marketo from trial flow : ${e.message}`);
    }
  }

  getGoogleClientId() {
    try {
      const tracker = window.ga.getAll()[0];
      return tracker.get("clientId");
    } catch (e) {
      return "n/a";
    }
  }

  initStatusCheck() {
    this.handleNewStatus(this.currentStatus, this.platformRegistrationLink);
    if (this.canCheckStatus()) {
      this.refreshIntervalCheck();
    }
  }

  refreshIntervalCheck() {
    if (this.intervalCheckStatus) {
      clearInterval(this.intervalCheckStatus);
    }
    this.intervalCheckStatus = setInterval(() => {
      this.checkStatus();
    }, this.delayBetweenCheck * 1000);
  }

  async checkStatus() {
    try {
      this.incrementElapsedTime();
      const statusResponse = await this.$jfCloudActivations.getStatus({
        serverName: this.flowDataProps.serverName,
        token: this.flowDataProps.token,
      });
      this.handleNewStatus(statusResponse.activationStatus, statusResponse.platformRegistrationLink);
    } catch (e) {
      this.$log.error(e);
      this.hasInternalError = true;
      clearInterval(this.intervalCheckStatus);
    }
  }

  canCheckStatus() {
    switch (this.currentStatus) {
      case "IN_PROGRESS":
      case "DELAYED":
        return true;
      case "READY":
      default:
        return false;
    }
  }

  handleNewStatus(newStatus: CloudActivationStatus, platformRegistrationLink: string) {
    this.currentStatus = newStatus;
    switch (newStatus) {
      case "READY":
        clearInterval(this.intervalCheckStatus);
        this.isReady = true;
        this.isDelayed = false;
        this.flowDataProps.isReady = true;
        this.platformRegistrationLink = platformRegistrationLink;
        this.flowDataProps.platformRegistrationLink = platformRegistrationLink;
        break;
      case "DELAYED":
        this.isDelayed = true;
        this.flowDataProps.isDelayed = true;
        break;
    }
  }

  incrementElapsedTime() {
    this.$jfCloudActivations.incrementElapsedTime(this.delayBetweenCheck);
    this.internalElapsedTime += this.delayBetweenCheck;
  }

  get inError() {
    return this.hasInternalError;
  }
}
