
declare var PLATFORM_DOMAIN: any;
declare var CONNECT_DOMAIN: any;
import { CloudActivationProps } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CloudTrialActivationFlowJfrogLogo",
})
export default class CloudTrialActivationFlowViaSuccessMail extends Vue {
  @Prop() private serverName!: CloudActivationProps["serverName"];
  @Prop() private instanceUrl!: CloudActivationProps["instanceUrl"];
  @Prop({ default: false })
  private isReady!: CloudActivationProps["isReady"];
  @Prop() private ssoType!: CloudActivationProps["ssoType"];
  @Prop() private platformRegistrationLink!: CloudActivationProps["platformRegistrationLink"];

  minSecondsBeforeRedirect: number = 60;
  delaySeconds: number = this.minSecondsBeforeRedirect / 100;

  mounted() {
    this.checkIfCanGoToPlatform();
  }

  goToPlatform() {
    if (this.platformRegistrationLink && this.platformRegistrationLink.length > 0) {
      window.location.href = this.platformRegistrationLink;
    } else {
      let url = this.instanceUrl || `https://${this.serverName}.${PLATFORM_DOMAIN}`;
      url += "/ui/login";

      if (url.indexOf("?") > -1) {
        url += "&firstRedirect=true";
      } else {
        url += "?firstRedirect=true";
      }

      if (this.ssoType) {
        url += "&auth_method=" + this.ssoType;
      }

      window.location.href = url;
    }
  }

  checkIfCanGoToPlatform() {
    if (this.isReady) {
      this.goToPlatform();
    }
    setTimeout(() => {
      this.checkIfCanGoToPlatform();
    }, this.delaySeconds * 1000);
  }
}
